<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5>
								<strong>Registro de Cortes</strong>
							</h5>
						</div>
						<div class="col-md-12">
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<insertOrUpdateCashFlow />
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import insertOrUpdateCashFlow from '@/components/insertOrUpdateCashFlow'
import { adminsList } from '@/constants'

export default {
	name: 'insertOrUpdateFlowView',
	components: {
		insertOrUpdateCashFlow,
	},
	computed: {
		...mapGetters('user', ['user']),
		spinnerLoaderLabel() {
			return this.$store.state.cashFlow.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.cashFlow.spinnerLoader
		},
		actualUser() {
			return !!adminsList.find((e) => e.id == this.user.user_id)
		},
	},
}
</script>