<template>
	<div>
		<div class="row">
			<div class="col-md-12 text-center" v-if="fileURL" style="padding-bottom: 20px">
				<pdf v-if="hasPDf" :src="fileURL"></pdf>
				<img :src="fileURL" class="img-fluid" v-else />
			</div>
			<div class="col-md-12 text-center" v-else style="padding-bottom: 20px">- Sin archivo adjunto -</div>
			<div class="col-md-12 text-right">
				<a-upload name="file" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload" :showUploadList="false" accept="application/pdf,image/*">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
				<button class="btn btn-info ml5" v-if="fileList.length" @click="handleUpload">Actualizar registro</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import pdf from 'vue-pdf'

export default {
	name: 'cashflowReceiptComponent',
	components: {
		pdf,
	},
	computed: {
		...mapGetters('cashFlow', ['actualRecord', 'fileURL']),
		hasPDf() {
			return this.fileURL.toLowerCase().split('.pdf').length == 2 ? true : false
		},
	},
	data() {
		return {
			fileList: [],
			apiURL: process.env.VUE_APP_API_URL,
		}
	},
	methods: {
		cleanData() {
			this.fileList = []
		},
		handleRemove() {
			this.fileList = []
		},
		beforeUpload(file) {
			this.fileList = [file]
			return false
		},
		handleUpload() {
			const { fileList } = this
			const formData = new FormData()

			formData.append('file', fileList[0])
			formData.append('id', this.actualRecord.id)

			this.$store.dispatch('cashFlow/UPLOAD_FILE', formData)
		},
	},
}
</script>