<template>
	<div>
		<div class="row">
			<div :class="localData.cashflow_action_id == 0 ? 'col-md-3' : 'col-md-4'">
				Tipo de movimiento
				<a-select v-model="localData.cash_flow_type" :style="{ width: '100%' }">
					<a-select-option value="in">Ingreso</a-select-option>
					<a-select-option value="out">Egreso</a-select-option>
				</a-select>
			</div>
			<div :class="localData.cashflow_action_id == 0 ? 'col-md-3' : 'col-md-4'">
				Movimiento
				<a-icon type="reload" @click="onReloadActions" :style="{ fontSize: '14px', color: 'red' }" />
				<a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="localData.cashflow_action_id" :style="{ width: '100%' }">
					<a-select-option v-for="(element, index) in cashflowActionsList" :key="index" :value="element.id">{{ element.description }}</a-select-option>
					<a-select-option :value="0">Otro</a-select-option>
				</a-select>
			</div>
			<div class="col-md-3" v-if="localData.cashflow_action_id == 0">
				Otro tipo de movimiento
				<a-input autocomplete="off" v-model="localData.cashflow_description" />
			</div>
			<div v-if="localData.cashflow_action_id != 17" :class="localData.cashflow_action_id == 0 ? 'col-md-3' : 'col-md-4'">
				¿Quién recibe?
				<a-icon type="reload" @click="onReloadUsers" :style="{ fontSize: '14px', color: 'red' }" />
				<a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="localData.receiver" :style="{ width: '100%' }">
					<a-select-option v-for="(element, index) in usersList" :key="index" :value="element.user_id">{{ element.fullName }}</a-select-option>
				</a-select>
			</div>
			<div class="col-md-4 pt10" v-if="localData.cashflow_action_id != 17">
				Efectivo
				<a-input prefix="$" v-money="money" autocomplete="off" v-model="localData.cash" />
			</div>
			<div class="col-md-4 pt10" v-if="localData.cashflow_action_id != 17">
				Terminal bancaria
				<a-input prefix="$" v-money="money" autocomplete="off" v-model="localData.bank_terminal" />
			</div>
			<div class="col-md-4" :class="localData.cashflow_action_id != 17 ? 'pt10' : ''">
				{{ `${localData.cashflow_action_id == 17 ? 'Monto' : 'Transferencia'}` }}
				<a-input prefix="$" v-money="money" autocomplete="off" v-model="localData.bank_transfer" />
			</div>
			<div class="col-md-4 pt10" v-if="localData.cashflow_action_id != 17">
				PayPal
				<a-input prefix="$" v-money="money" autocomplete="off" v-model="localData.paypal" />
			</div>
			<div class="col-md-4 pt10" v-if="localData.cashflow_action_id != 17">
				MercadoPago
				<a-input prefix="$" v-money="money" autocomplete="off" v-model="localData.mercadopago" />
			</div>
			<div class="col-md-4 pt10" v-if="localData.cashflow_action_id != 17">
				Otros
				<a-input prefix="$" v-money="money" autocomplete="off" v-model="localData.other" />
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12">
				Comentarios
				<a-textarea v-model="localData.comments" />
			</div>
		</div>
		<div class="row pt20">
			<div class="col-md-4 text-left">
				<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar </a-button>
			</div>
			<div class="col-md-8 text-right" v-if="!localData.status || localData.status == 0">
				<a-button class="ml5 btn btn-info" icon="file" @click="onSetReceipt" v-if="!isNewRecord">Comprobante</a-button>
				<a-button class="ml5 btn btn-success" icon="save" @click="onSave" v-if="isNewRecord">Guardar</a-button>
			</div>
		</div>
		<a-modal :visible="modalReceipt.visible" width="500px" :footer="null" title="Comprobante" :closable="true" @cancel="handleCancelReceipt">
			<cashflowReceipt v-if="modalReceipt.visible" />
		</a-modal>
	</div>
</template>

<script>
//
import { mapGetters } from 'vuex'
import _ from 'lodash'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'
import { cashFlowModel } from '@/constants'
import { VMoney } from 'v-money'
import numeral from 'numeral'
import moment from 'moment'
import cashflowReceipt from '@/components/cashflowReceipt'

export default {
	name: 'insertOrUpdateCashFlowComponent',
	directives: {
		money: VMoney,
	},
	components: {
		cashflowReceipt,
	},
	data() {
		return {
			localData: _.cloneDeep(cashFlowModel.cashFlowStructure),
			money: null,
			modalReceipt: {
				visible: false,
			},
		}
	},
	computed: {
		...mapGetters('users', ['usersList']),
		...mapGetters('user', ['user']),
		...mapGetters('cashFlow', ['actualRecord']),
		...mapGetters('cashflowActions', ['cashflowActionsList']),
		isNewRecord() {
			return !!!utilities.objectValidate(this.$route, 'params.id', false)
		},
	},
	mounted() {
		if (!this.usersList.length) {
			this.$store.dispatch('users/GET')
		}
		if (!this.cashflowActionsList.length) {
			this.$store.dispatch('cashflowActions/GET')
		}
		if (this.isNewRecord) {
			// set receiver
			this.localData.receiver = this.user.user_id
			this.setMoneyFormat()
		} else {
			//
			let localData = _.cloneDeep(this.actualRecord)
			this.localData = {
				...localData,
				cash: `${numeral(localData.cash).format('0,0.00')}`,
				bank_terminal: `${numeral(localData.bank_terminal).format('0,0.00')}`,
				bank_transfer: `${numeral(localData.bank_transfer).format('0,0.00')}`,
				paypal: `${numeral(localData.paypal).format('0,0.00')}`,
				mercadopago: `${numeral(localData.mercadopago).format('0,0.00')}`,
				other: `${numeral(localData.other).format('0,0.00')}`,
			}
			setTimeout(() => {
				this.setMoneyFormat()
			})
		}
	},
	methods: {
		moment,
		setMoneyFormat() {
			this.money = {
				decimal: '.',
				thousands: ',',
				precision: 2,
			}
		},
		onSave() {
			let errors = []
			// se evalúa quién recibe el dinero
			if (this.localData.receiver == '') {
				errors.push('- Selecciona quién recibe')
			}
			// se evalúa si hay un monto a entregar
			if (
				numeral(this.localData.cash).value() < 1 &&
				numeral(this.localData.bank_terminal).value() < 1 &&
				numeral(this.localData.bank_transfer).value() < 1 &&
				numeral(this.localData.paypal).value() < 1 &&
				numeral(this.localData.mercadopago).value() < 1
			) {
				errors.push('- Ingresa al menos un monto por método de pago')
			}

			if (errors.length) {
				Swal.fire({
					title: 'Ingresos',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}

			let payload = {
				..._.cloneDeep(this.localData),
				issuing: this.user.user_id,
			}

			if (this.isNewRecord) {
				this.$store.dispatch('cashFlow/CREATE', payload)
			} else {
				payload.id = this.$route.params.id
				this.$store.dispatch('cashFlow/UPDATE', payload)
			}
		},
		onCancel() {
			this.$router.go(-1)
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onReloadUsers() {
			this.$store.dispatch('users/GET', true)
		},
		handleCancelReceipt() {
			this.modalReceipt.visible = false
		},
		onSetReceipt() {
			this.modalReceipt.visible = true
		},
		onReloadActions() {
			this.$store.dispatch('cashflowActions/GET')
		},
	},
	watch: {
		'localData.cashflow_action_id': {
			handler(newData) {
				if (newData > 0) {
					this.localData.cashflow_description = this.cashflowActionsList.find((e) => e.id == newData).description
				} else {
					this.localData.cashflow_description = ''
				}
			},
		},
	},
}
</script>